<template>
    <modal
        id="delete"
        ref="delete"
        :title="$t('modal.confirm.heading')"
        type="user"
    >
        <div class="d-flex">
            <b-button
                variant="primary"
                @click="deleteItem()"
            >
                {{ $t('modal.confirm.yesButton') }}
            </b-button>
            <b-button
                variant="text"
                class="stroke"
                @click="closeModal()"
            >
                {{ $t('modal.confirm.noButton') }}
            </b-button>
        </div>
    </modal>
</template>

<script>
    import Modal from './Modal.vue';
    export default {
        components: { Modal },
        name: 'DeleteDataModal',
        methods: {
            closeModal() {
                this.$refs.delete.closeModal();
            },
            deleteItem() {
                this.$emit('deleteItem');
                this.closeModal();
            },
        },
    };
</script>
